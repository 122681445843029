<template>
  <div class="container container-table">
    <div class="columns is-centered is-multiline">
      <div class="container column is-8">
        <div style="max-width:18em; margin: 0 auto;">
          <a href="/" class="edshedlogo">
            <img src="/img/logo-edshed.png" alt="Logo" class="img-responsive center-block">
          </a>
        </div>
        <div class="center-block" style="width: fit-content; text-align: center">
          <img src="/img/logo.png" class="inline-block sublogo">
          <img v-if="$route.query.locale == 'en_US'" src="/img/math-shed-logo-dark.png" class="inline-block sublogo mathslogo">
          <img v-else src="/img/maths-shed-logo-dark.png" class="inline-block sublogo mathslogo">
        </div>
      </div>
      <!-- <img src="/img/Bee.svg" alt="Logo" class="img-responsive center-block logo"> -->
      <div class="column is-8">
        <div class="box box-info" style="color:black">
          <div class="box-header content with-border">
            <div class="text-center">
              <h1>{{ this.$route.name.toUpperCase() }}</h1>
              <h3 class="tag is-light is-large">
                {{ this.$store.state.user.email }}
              </h3>
              <p>
                If your email address is incorrect please click to edit: <button class="button is-link is-small" @click="editEmail">
                  Edit Email
                </button>
              </p>
            </div>
          </div>
          <div class="box-body">
            <!-- login form -->
            <p>You must verify your email address to continue. Please check your mailbox or click below to send a new verification email.</p>
            <button style="margin: 30px;" class="button is-link" @click="sendVerification">
              Send Verification Email
            </button>
            <!-- errors -->
            <div class="text-center col-md-12">
              <router-link to="/logout">
                Logout
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        &nbsp;
    </div>
    <div class="row">
      <div class="container">
        <div class="text-center col-md-4 col-sm-offset-4">
          <p style="font-size:14px; color: #4a4a4a;">
            Copyright &copy; {{ new Date().getFullYear() }} Education Shed Ltd.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/edshed-common/api'
import utils from '@/utils'
export default {
  name: 'UnverifiedView',
  mounted () {
    this.$nextTick(() => {
      if (!this.$store.state.user || !this.$store.state.user.email || this.$store.state.user.email === '') {
        this.$router.push({ path: '/logout' })
      }
      this.fetchUser()
    })
  },
  methods: {
    fetchUser () {
      request('GET', 'users/me/refresh', null)
        .then((response) => {
          window.console.log('Response', response)
          const data = response.data

          /* Setting user in the state and caching record to the localStorage */
          if (data.user) {
            this.$store.commit('SET_USER', data.user)

            console.log('user' + JSON.stringify(data.user))

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
            }

            this.response = 'Details saved'
            if (data.user.email_verified) {
              this.$router.push('/')
            }
          }
        })
        .catch((error) => {
          console.log(error)

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    editEmail () {
      const c = prompt('Enter your email address', this.$store.state.user.email)
      if (c) {
        console.log(c)
        if (c.trim() === '' || !utils.isEmail(c.trim())) {
          alert('Email address must be valid')
          return
        }
        const d = { email: c }

        /* Making API call to authenticate a user */
        request('PUT', 'users/me', d)
          .then((response) => {
            window.console.log('Response', response)
            const data = response.data

            /* Setting user in the state and caching record to the localStorage */
            if (data.user) {
              this.$store.commit('SET_USER', data.user)

              console.log('user' + JSON.stringify(data.user))

              if (window.localStorage) {
                window.localStorage.setItem('user', JSON.stringify(data.user))
              }

              this.response = 'Details saved'
              if (!data.user.email_verified) {
                window.location.replace('https://www.edshed.com/unverified')
              }
            }
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.response = 'Details incorrect'
          })
      }
    },
    sendVerification () {
      request('POST', 'users/me/sendverifyemail', null)
        .then((response) => {
          // console.log(response)
          alert('Email requested')
        })
        .catch((error) => {
          console.log(error)
          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
        })
    }
  }
}
</script>
